<template>
  <div id="Site" class="wrap">
    <van-nav-bar
      :fixed="true"
      placeholder
      :title="$t('My.PersonalInformation.Site.title')"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar color="#333" background="#eee">
      {{ $t('My.PersonalInformation.Site.ReceivingIdentity') }}
    </van-notice-bar>
    <van-field
      colon
      v-model="DataArr.Name"
      :label="$t('My.PersonalInformation.BankAccounts.RealName')"
      :placeholder="$t('My.PersonalInformation.BankAccounts.RealNameNote')"
    />
    <van-field
      colon
      v-model="DataArr.Phone"
      :label="$t('My.PersonalInformation.BankAccounts.PhoneNo')"
      :placeholder="$t('My.PersonalInformation.BankAccounts.PhoneNoNote')"
    />
    <!-- 第一部分的输入框 -->
    <van-notice-bar color="#333" background="#eee">
      {{ $t('My.PersonalInformation.Site.ReceivingAddress') }}
    </van-notice-bar>
    <van-field
      colon
      label-width="4rem"
      type="textarea"
      v-model="DataArr.Detailed"
      :label="$t('My.PersonalInformation.Site.DetailedAddress')"
      :placeholder="$t('My.PersonalInformation.Site.DetailedAddressNote')"
    />
    <!-- 第二部分的输入框-->
    <div class="button-confirm">
      <van-button size="normal" type="default" block @click="ButtomEvent()">{{
        $t('My.PersonalInformation.Site.Button')
      }}</van-button>
    </div>
    <!-- 第三部分的按钮 -->
    <div class="List-content">
      <div class="content-item" v-for="(item, index) in SiteData" :key="index">
        <div class="item-text">
          {{ $t('My.PersonalInformation.BankAccounts.RealName') }}：&nbsp;<span
            >{{ item.CONSIGNEE }}</span
          >
        </div>
        <div class="item-text">
          {{ $t('My.PersonalInformation.BankAccounts.PhoneNo') }}：&nbsp;<span
            >{{ item.ADDRESS }}</span
          >
        </div>
        <div class="item-text">
          {{ $t('My.PersonalInformation.Site.DetailedAddress') }}：&nbsp;<span
            >{{ item.PHONE }}</span
          >
        </div>
        <span class="item-button" @click="DeleteEvent(item.ID)">删除</span>
        <span class="item-button" @click="DefaultEvent(item.ID)"
          >设置默认地址</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { REQUEST_API } from '@/http/api' // 引入封装的axios
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  Switch,
  Toast,
} from 'vant'

export default {
  name: 'Site',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Switch.name]: Switch,
    [Toast.name]: Toast,
  },
  data() {
    return {
      value: 'Aabb2021',
      DataArr: {
        Name: '',
        Phone: '',
        Detailed: '',
      },
      SiteData: [], // 存放用户所有地址的
    }
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    ButtomEvent() {
      if (
        this.DataArr.Name != '' &&
        this.DataArr.Phone != '' &&
        this.DataArr.Detailed != ''
      ) {
        let form = {
          cmd: '202321',
          uid: this.module.UIDEvent(),
          address: this.DataArr.Detailed,
          phone: this.DataArr.Phone,
          consignee: this.DataArr.Name,
        }
        REQUEST_API(form)
          .then((r) => {
            if (r.data.sc == 0) {
              Object.assign(this.$data.DataArr, this.$options.data().DataArr) //基础信息重新赋值
              Toast(this.$t('Hint.success'))
              this.RequestEvent() // 获取地区的
            } else {
              Toast(this.$t('Hint.failed'))
            }
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        Toast(this.$t('My.PersonalInformation.Site.hint'))
      }
    },
    // 获取地区列表的
    RequestEvent() {
      let form = {
        cmd: '202323',
        uid: this.module.UIDEvent(),
      }
      REQUEST_API(form)
        .then((r) => {
          if (r.data.sc == 0) {
            this.SiteData = r.data.notifyList[0].data
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    // 删除当前地址的
    DeleteEvent(id) {
      let form = {
        cmd: '202322',
        uid: this.module.UIDEvent(),
        id: id,
      }
      REQUEST_API(form)
        .then((r) => {
          if (r.data.sc == 0) {
            Toast(this.$t('Hint.success'))
            this.RequestEvent() // 获取地区的
          } else {
            Toast(this.$t('Hint.failed'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    // 设置为当前地址为默认的值
    DefaultEvent(id) {
      let form = {
        cmd: '202324',
        uid: this.module.UIDEvent(),
        id: id,
      }
      REQUEST_API(form)
        .then((r) => {
          if (r.data.sc == 0) {
            Toast(this.$t('Hint.success'))
            this.RequestEvent() // 获取地区的
          } else {
            Toast(this.$t('Hint.failed'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  created() {
    this.RequestEvent() // 获取地区的
  },
}
</script>

<style lang="less">
#Site {
  width: 100%;
  height: 100%;
  .van-cell {
    .van-cell__title {
      > span {
        font-size: 0.875rem;
      }
    }
  }
  .switch-content {
    width: 90%;
    margin: 0.5rem auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > span {
      font-size: 0.875rem;
      color: #646566;
    }
  }
  .button-confirm {
    width: 100%;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    .van-button {
      width: 90%;
      margin: 0 auto;
      border-radius: 0.5rem;
      background-color: #2f3848;
      color: #ffffff;
    }
  }
  .List-content {
    width: 90%;
    margin: 0 auto;
    > .content-item {
      border: 1px solid #cccccc;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      padding: 1rem;
      > .item-text {
        font-size: 0.875rem;
        margin-bottom: 0.3rem;
        color: #333333;
        > span {
          color: #666666;
        }
      }
      > .item-button {
        font-size: 0.875rem;
        margin-right: 0.5rem;
        color: #0033FF;
      }
    }
  }
}
</style>